/* DIP */
.dip {
  #nav {
    ul {
      background: #103d85;
      width: auto;
      a.is-active,
      a:hover,
      li:hover a.submenu,
      li:hover span.submenu,
      ul.submenu a.is-active,
      ul.submenu a:hover {
        background: #144da7;
      }
    }
    #menutoggle {
      background: #103d85;
      &:hover,
      &:active,
      &.is-active {
        background-color: lighten(#103d85, 7.5%);
      }
    }
  }
  #header {
    a.logo {
      max-width: 240px;
      img {
        max-width: 100px;
        margin-right: 5px;
        &.dds-logo {
          max-width: 215px;
        }
        &.mvcpc-logo {
          max-width: 160px;
        }
      }
    }
    .phone {
      position: absolute;
      right: 0;
      bottom: -36px;
      background: #103d85;
      border-radius: 0 0 3px 3px;
      padding: 5px 10px;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    color: #00529b;
  }
  #footer-wrap {
    background: #333;
    p,
    a,
    h2 {
      color: #fff;
    }
  }
  .footer {
    padding-top: 0;
  }
  ul.check > li:before {
    color: #0fa12e;
  }
  #banner-wrap {
    background: url(/images/dip/bg.jpg) no-repeat top center;
    background-size: cover;
    #banner {
      .buttons {
        .cta-btn {
          max-width: 150px;
        }
        .dropdown-list {
          width: 100%;
          max-width: 200px;
          span.dropdown.cta-dropdown {
            padding: 12px 10px;
            color: #fff;
            font-weight: 600;
            background: #65af36;
            border: 0;
            width: 100%;
            height: auto;
            line-height: 1;
            font-size: 15px;
            text-align: left;
            text-transform: uppercase;
            & + .drop {
              top: 39px;
            }
            &:active,
            &:hover,
            &.is-active {
              background: #55942e;
            }
          }
        }
        .signup:before {
          margin-right: 5px;
          width: inherit;
          height: inherit;
          content: url(/images/dip/icon-cart.png);
          color: inherit;
          text-align: center;
          vertical-align: top;
        }
      }
      .banner-points {
        margin-top: -30px;
      }
    }
  }
  #content-wrap {
    a.signup {
      font-weight: 600;
      border: 0;
      text-shadow: 0 0 0;
      font-family:
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
      font-size: 15px;
      max-width: 150px;
      &.large {
        max-width: 250px;
      }
    }
    .price-display {
      background: #d7d7d7;
      p {
        font-size: 2em;
      }
    }
  }
  .cta-btn {
    font-weight: 600;
    border: 0;
    text-shadow: 0 0 0;
    font-family:
      'Open Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      'Helvetica Neue',
      Arial,
      'Noto Sans',
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 15px;
  }
  #pricing-wrap {
    background: url(/images/dip/bg.jpg) no-repeat top center;
    background-size: cover;
    &.dark {
      background: #333;
    }
    &.bg-blue {
      background: #00529b;
    }
    .price-display {
      background: #d7d7d7;
      p {
        font-size: 2em;
      }
    }
    a.signup {
      max-width: 150px;
    }
    .dropdown-list {
      width: 100%;
      &.cta-dropdown-wrap {
        max-width: 200px;
      }
      span.dropdown.cta-dropdown {
        padding: 12px 10px;
        color: #fff;
        font-weight: 600;
        background: #103d85;
        border: 0;
        width: 100%;
        height: auto;
        line-height: 1;
        font-size: 15px;
        text-align: left;
        text-transform: uppercase;
        & + .drop {
          top: 39px;
        }
        &:active,
        &:hover,
        &.is-active {
          background: #144ca5;
        }
      }
    }
    .select-course {
      h3 {
        background: #70b444;
        border-radius: 3px 3px 0 0;
        &.bg-blue {
          background: #00529b;
        }
      }
    }
  }
  #testimonials-wrap {
    background: url(/images/dip/bg.jpg) no-repeat bottom center;
    background-size: cover;
    #testimonials-text {
      background: rgba(255, 255, 255, 0.85) url(/images/dip/quotes-box.png) no-repeat center center;
      background-size: contain;
      margin: 0 auto;
      p {
        color: #555;
        font-weight: 600;
        font-size: 1.25em;
      }
    }
  }
  &.az {
    #banner-wrap {
      background: url(/images/dip/az-bg.jpg) no-repeat bottom center;
      background-size: cover;
      #banner h1,
      #banner h2 {
        color: #00529b;
        text-shadow: 0 0px 6px rgba(255, 255, 255, 0.35);
      }
      .course-fee {
        background: #70b444;
        margin: 30px -24px -25px -24px;
        border-radius: 0 0 3px 3px;
        p {
          font-size: 1.5em;
        }
      }
    }
    #footer-wrap {
      background: #00529b;
    }
  }
  #feesTable {
    background: #ebebeb;
    border: 5px solid #ebebeb;
    font-size: 13px;
  }
  #feesTabs {
    background: #ebebeb;
    button {
      margin: 2px 0;
      background: #ebebeb;
      color: #bd2120;
      border: 0;
      &:hover,
      &.active {
        background: #fff;
      }
    }
  }
  #feesTabContent {
    background: #ebebeb;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .dip {
    #feesTabs {
      .tab-nav {
        float: left;
        width: 15%;
        li {
          display: block;
          width: 100%;
        }
      }
      #tabs {
        width: 85%;
      }
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  .dip {
    #header-wrap {
      border-top: 0;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .dip {
    #nav {
      float: right;
      height: 40px;
      width: auto;
      background: none;
      clear: none;
      padding: 15px 0;
      li.register {
        background: #103d85;
        border-radius: 3px;
        &:hover,
        a:hover {
          background: lighten(#103d85, 8%);
        }
        a {
          color: #fff;
        }
      }
      a {
        color: #555;
        text-shadow: 0 0 0;
        font-weight: 600;
        &:hover,
        &.is-active {
          color: #fff;
          background: #103d85;
        }
        &.livechat:before {
          display: inline-block;
          margin-right: 5px;
          width: inherit;
          height: inherit;
          content: '\e830';
          color: inherit;
          font-family: 'icons';
          font-weight: normal;
          text-align: center;
          font-size: 105%;
          vertical-align: top;
        }
      }
    }

    #header-wrap {
      border-top: 0;
      border-bottom: 1px solid #ccc;
    }
    #nav {
      a {
        padding: 0px 8px;
      }
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {
  .dip {
    #header a.logo img.mvcpc-logo {
      max-width: 215px;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
