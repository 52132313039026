/* DIP */
.dip #nav ul {
  background: #103d85;
  width: auto;
}
.dip #nav ul a.is-active,
.dip #nav ul a:hover,
.dip #nav ul li:hover a.submenu,
.dip #nav ul li:hover span.submenu,
.dip #nav ul ul.submenu a.is-active,
.dip #nav ul ul.submenu a:hover {
  background: #144da7;
}
.dip #nav #menutoggle {
  background: #103d85;
}
.dip #nav #menutoggle:hover, .dip #nav #menutoggle:active, .dip #nav #menutoggle.is-active {
  background-color: #144da7;
}
.dip #header a.logo {
  max-width: 240px;
}
.dip #header a.logo img {
  max-width: 100px;
  margin-right: 5px;
}
.dip #header a.logo img.dds-logo {
  max-width: 215px;
}
.dip #header a.logo img.mvcpc-logo {
  max-width: 160px;
}
.dip #header .phone {
  position: absolute;
  right: 0;
  bottom: -36px;
  background: #103d85;
  border-radius: 0 0 3px 3px;
  padding: 5px 10px;
}
.dip h1,
.dip h2,
.dip h3,
.dip h4 {
  color: #00529b;
}
.dip #footer-wrap {
  background: #333;
}
.dip #footer-wrap p,
.dip #footer-wrap a,
.dip #footer-wrap h2 {
  color: #fff;
}
.dip .footer {
  padding-top: 0;
}
.dip ul.check > li:before {
  color: #0fa12e;
}
.dip #banner-wrap {
  background: url(/images/dip/bg.jpg) no-repeat top center;
  background-size: cover;
}
.dip #banner-wrap #banner .buttons .cta-btn {
  max-width: 150px;
}
.dip #banner-wrap #banner .buttons .dropdown-list {
  width: 100%;
  max-width: 200px;
}
.dip #banner-wrap #banner .buttons .dropdown-list span.dropdown.cta-dropdown {
  padding: 12px 10px;
  color: #fff;
  font-weight: 600;
  background: #65af36;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  text-transform: uppercase;
}
.dip #banner-wrap #banner .buttons .dropdown-list span.dropdown.cta-dropdown + .drop {
  top: 39px;
}
.dip #banner-wrap #banner .buttons .dropdown-list span.dropdown.cta-dropdown:active, .dip #banner-wrap #banner .buttons .dropdown-list span.dropdown.cta-dropdown:hover, .dip #banner-wrap #banner .buttons .dropdown-list span.dropdown.cta-dropdown.is-active {
  background: #55942e;
}
.dip #banner-wrap #banner .buttons .signup:before {
  margin-right: 5px;
  width: inherit;
  height: inherit;
  content: url(/images/dip/icon-cart.png);
  color: inherit;
  text-align: center;
  vertical-align: top;
}
.dip #banner-wrap #banner .banner-points {
  margin-top: -30px;
}
.dip #content-wrap a.signup {
  font-weight: 600;
  border: 0;
  text-shadow: 0 0 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  max-width: 150px;
}
.dip #content-wrap a.signup.large {
  max-width: 250px;
}
.dip #content-wrap .price-display {
  background: #d7d7d7;
}
.dip #content-wrap .price-display p {
  font-size: 2em;
}
.dip .cta-btn {
  font-weight: 600;
  border: 0;
  text-shadow: 0 0 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
}
.dip #pricing-wrap {
  background: url(/images/dip/bg.jpg) no-repeat top center;
  background-size: cover;
}
.dip #pricing-wrap.dark {
  background: #333;
}
.dip #pricing-wrap.bg-blue {
  background: #00529b;
}
.dip #pricing-wrap .price-display {
  background: #d7d7d7;
}
.dip #pricing-wrap .price-display p {
  font-size: 2em;
}
.dip #pricing-wrap a.signup {
  max-width: 150px;
}
.dip #pricing-wrap .dropdown-list {
  width: 100%;
}
.dip #pricing-wrap .dropdown-list.cta-dropdown-wrap {
  max-width: 200px;
}
.dip #pricing-wrap .dropdown-list span.dropdown.cta-dropdown {
  padding: 12px 10px;
  color: #fff;
  font-weight: 600;
  background: #103d85;
  border: 0;
  width: 100%;
  height: auto;
  line-height: 1;
  font-size: 15px;
  text-align: left;
  text-transform: uppercase;
}
.dip #pricing-wrap .dropdown-list span.dropdown.cta-dropdown + .drop {
  top: 39px;
}
.dip #pricing-wrap .dropdown-list span.dropdown.cta-dropdown:active, .dip #pricing-wrap .dropdown-list span.dropdown.cta-dropdown:hover, .dip #pricing-wrap .dropdown-list span.dropdown.cta-dropdown.is-active {
  background: #144ca5;
}
.dip #pricing-wrap .select-course h3 {
  background: #70b444;
  border-radius: 3px 3px 0 0;
}
.dip #pricing-wrap .select-course h3.bg-blue {
  background: #00529b;
}
.dip #testimonials-wrap {
  background: url(/images/dip/bg.jpg) no-repeat bottom center;
  background-size: cover;
}
.dip #testimonials-wrap #testimonials-text {
  background: rgba(255, 255, 255, 0.85) url(/images/dip/quotes-box.png) no-repeat center center;
  background-size: contain;
  margin: 0 auto;
}
.dip #testimonials-wrap #testimonials-text p {
  color: #555;
  font-weight: 600;
  font-size: 1.25em;
}
.dip.az #banner-wrap {
  background: url(/images/dip/az-bg.jpg) no-repeat bottom center;
  background-size: cover;
}
.dip.az #banner-wrap #banner h1,
.dip.az #banner-wrap #banner h2 {
  color: #00529b;
  text-shadow: 0 0px 6px rgba(255, 255, 255, 0.35);
}
.dip.az #banner-wrap .course-fee {
  background: #70b444;
  margin: 30px -24px -25px -24px;
  border-radius: 0 0 3px 3px;
}
.dip.az #banner-wrap .course-fee p {
  font-size: 1.5em;
}
.dip.az #footer-wrap {
  background: #00529b;
}
.dip #feesTable {
  background: #ebebeb;
  border: 5px solid #ebebeb;
  font-size: 13px;
}
.dip #feesTabs {
  background: #ebebeb;
}
.dip #feesTabs button {
  margin: 2px 0;
  background: #ebebeb;
  color: #bd2120;
  border: 0;
}
.dip #feesTabs button:hover, .dip #feesTabs button.active {
  background: #fff;
}
.dip #feesTabContent {
  background: #ebebeb;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
@media (min-width: 768px) {
  .dip #feesTabs .tab-nav {
    float: left;
    width: 15%;
  }
  .dip #feesTabs .tab-nav li {
    display: block;
    width: 100%;
  }
  .dip #feesTabs #tabs {
    width: 85%;
  }
}
@media only screen and (min-width: 56.25em) {
  .dip #header-wrap {
    border-top: 0;
  }
}
@media (min-width: 992px) {
  .dip #nav {
    float: right;
    height: 40px;
    width: auto;
    background: none;
    clear: none;
    padding: 15px 0;
  }
  .dip #nav li.register {
    background: #103d85;
    border-radius: 3px;
  }
  .dip #nav li.register:hover,
  .dip #nav li.register a:hover {
    background: #144ea9;
  }
  .dip #nav li.register a {
    color: #fff;
  }
  .dip #nav a {
    color: #555;
    text-shadow: 0 0 0;
    font-weight: 600;
  }
  .dip #nav a:hover, .dip #nav a.is-active {
    color: #fff;
    background: #103d85;
  }
  .dip #nav a.livechat:before {
    display: inline-block;
    margin-right: 5px;
    width: inherit;
    height: inherit;
    content: "\e830";
    color: inherit;
    font-family: "icons";
    font-weight: normal;
    text-align: center;
    font-size: 105%;
    vertical-align: top;
  }
  .dip #header-wrap {
    border-top: 0;
    border-bottom: 1px solid #ccc;
  }
  .dip #nav a {
    padding: 0px 8px;
  }
}
@media (min-width: 1080px) {
  .dip #header a.logo img.mvcpc-logo {
    max-width: 215px;
  }
}